import getShortIsoString from '@finst/core/src/scripts/utils/date/get-short-iso-string';
import { useCallback, useEffect, useRef, useState } from 'preact/compat';
const identity = (value)=>value;
export default function useFormInputValue(initialValue, normalizeValue) {
    const normalizeValueRef = useRef(normalizeValue);
    const [value, setValue] = useState(initialValue);
    useEffect(()=>{
        normalizeValueRef.current = normalizeValue;
    }, [
        normalizeValue
    ]);
    const reset = useCallback(()=>setValue(initialValue), [
        initialValue
    ]);
    const handleInput = useCallback((event)=>{
        const { current: normalizeValue = identity } = normalizeValueRef;
        const el = event.currentTarget;
        if (el.type === 'checkbox') {
            const { checked } = el;
            setValue(normalizeValue(checked));
        } else if (el.type === 'date') {
            const { valueAsDate } = el;
            if (valueAsDate) {
                const value = getShortIsoString(valueAsDate);
                setValue(normalizeValue(value));
            }
        } else if (el.type === 'file') {
            const { files } = el;
            if (files) {
                setValue(normalizeValue(Array.from(files)));
            }
        } else {
            setValue(normalizeValue(el.value));
        }
    }, []);
    return {
        value,
        handleInput,
        reset
    };
}
